/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query HomeBioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        flexDirection: "column",
        marginBottom: rhythm(2.5),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author.name}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
          alignSelf: "center",
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <article>
        <h1>
          Hi, I'm Vinícius! <span role="img">👋</span>
        </h1>
        <p>
          I'm a Software Engineer who works currently as Engineering Manager in
          Berlin.
          {/* Every now and then I drop some thoughts around software
          engineering, teamwork, and leadership here. */}
        </p>
        <p>
          If you want to contact me to talk about the topics I'm interested in
          over a cup of coffee, please feel free to shoot me a message :)
        </p>
        <p>
          <strong>Currently interested in:</strong>
          <ul style={{ margin: 30 }}>
            <li>Leadership</li>
            <li>High performing teams</li>
            <li>Stoicism</li>
            <li>Parenting</li>
            <li>Software Engineering</li>
            <li>Jazz</li>
            <li>
              Helping and mentoring people who need to start their careers in
              software engineering.
            </li>
          </ul>
        </p>
      </article>
    </div>
  )
}

export default Bio
